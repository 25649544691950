.background-container {
  display: flex;
  justify-content: center;
  position: fixed;
  inset: 0;
  z-index: -1;
}

.bg-shape-1 {
  width: 20vw;
  height: 20vw;
  border-radius: 999px;
  position: relative;
  animation: one 10s infinite;
  background-color: white;
  opacity: 50%;
}

.bg-shape-2 {
  width: 25vw;
  height: 25vw;
  border-radius: 999px;
  position: relative;
  animation: two 10s infinite;
  background-color: var(--color-primary);
  opacity: 50%;
}
.bg-shape-3 {
  width: 20vw;
  height: 20vw;
  border-radius: 999px;
  position: relative;
  animation: three 10s infinite;
  box-sizing: border-box;
  background-color: white;
  opacity: 50%;
}
@keyframes one {
  0% {
    left: 0%;
    top: 0%;
  }
  25% {
    left: -10%;
    top: 25%;
  }
  50% {
    left: 2%;
    top: 50%;
  }
  75% {
    left: 15%;
    top: 75%;
  }
  100% {
    left: 0%;
    top: 0%;
  }
}
@keyframes two {
  0% {
    left: 0%;
    top: 0%;
  }
  25% {
    left: 0%;
    top: 25%;
  }
  50% {
    left: 12%;
    top: 50%;
  }
  75% {
    left: 15%;
    top: 75%;
  }
  100% {
    left: 0%;
    top: 0%;
  }
}
@keyframes three {
  0% {
    left: 0%;
    top: 0%;
  }
  25% {
    left: 10%;
    top: 25%;
  }
  50% {
    left: 22%;
    top: 50%;
  }
  75% {
    left: 15%;
    top: 75%;
  }
  100% {
    left: 0%;
    top: 0%;
  }
}

.bg-blur {
  filter: blur(150px);
}
