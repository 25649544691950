.appointment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
.calendar {
  font-family: "Outfit Bold";
  font-size: 24px;
  border: none;
  width: 350px;
  height: 400px;
  background-image: url("../../../assets/images/Calender.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  margin-right: 50px;
}
.text {
  width: 50%;
}
@media screen and (max-width: 884px) {
  .appointment {
    flex-direction: column;
    justify-content: center;
  }
  .text {
    text-align: center;
    margin-top: 50px;
    width: 80%;
  }
  .calendar {
    margin-right: 0px;
  }
}
@media screen and (max-width: 430px) {
  .calendar {
    width: 250px;
    height: 297px;
  }
}

.calendly-inline-widget iframe,
.calendly-badge-widget iframe,
.calendly-overlay iframe {
  min-width: 320px !important;
  height: 750px !important;
}
