.letter-list {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "Zcool Regular";
  display: flex;
  transform: translate(-50%, -50%);
  padding: 0 !important;
  margin: 0 !important;
}
.repeat-text {
  font-size: 80px;
}
.rebeat {
  margin-bottom: 60%;
}
.section1-header {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.section1-footer {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  overflow: hidden;
  margin: 0 auto;
}
/*section animation */

.s2-w1-animation {
  display: inline-block;
  animation: animateWord 0.5s forwards;
}

@keyframes animateWord {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.section-continue {
  font-size: 16px;
  font-family: "Outfit Light";
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.letter-item {
  list-style: none;

  animation: animation 3s linear infinite;
}
.letter-item:nth-child(1) {
  animation-delay: 0.1s;
}
.letter-item:nth-child(2) {
  animation-delay: 0.3s;
}
.letter-item:nth-child(3) {
  animation-delay: 0.5s;
}
.letter-item:nth-child(4) {
  animation-delay: 0.7s;
}
.letter-item:nth-child(5) {
  animation-delay: 0.9s;
}
.letter-item:nth-child(6) {
  animation-delay: 1.1s;
}
.letter-item:nth-child(7) {
  animation-delay: 1.3s;
}
.letter-item:nth-child(8) {
  animation-delay: 1.5s;
}
.letter-item:nth-child(9) {
  animation-delay: 1.7s;
}
.letter-item:nth-child(10) {
  animation-delay: 1.9s;
}
.letter-item:nth-child(11) {
  animation-delay: 2.2s;
}
.letter-item:nth-child(12) {
  animation-delay: 2.4s;
}
.letter-item:nth-child(13) {
  animation-delay: 2.6s;
}
.letter-item:nth-child(14) {
  animation-delay: 2.8s;
}

@keyframes animation {
  0% {
    color: #fff;
    text-shadow: none;
  }
  50% {
    color: var(--color-primary);
    text-shadow: none;
  }
  100% {
    color: var(--color-primary);
    text-shadow: 0 0 7px var(--color-primary), 0 0 70px var(--color-primary);
  }
}

.revolver {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 60%;
  height: auto;
  overflow: hidden;
  z-index: -1;
}
/* .revolver {
  display: flex;
  width: 40%;
} */
.svg {
  transition: transform 0.5s ease;
  display: none;
}

.app-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100vh !important;
  /* flex-direction: row-reverse !important; */
}
#section1 {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
}

#section2 {
  display: flex;
  justify-content: center;
  display: none;
}

#section3 {
  color: white;
  display: flex;
  justify-content: center;
  display: none;
}

#section4 {
  color: white;
  display: flex;
  justify-content: center;
  display: none;
}

#section5 {
  color: white;
  display: flex;
  justify-content: center;
  display: none;
}

#section6 {
  color: white;
  display: flex;
  justify-content: center;
  display: none;
}
#section7 {
  color: white;
  display: flex;
  justify-content: center;
  display: none;
}

.outreach-container {
  background-image: url("../../assets/images/outreach-vector.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: auto;
  margin-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  background-position: center;
  position: relative;
  overflow: hidden;
}
.roofing6 {
  width: 80%;
}
.rotate-image {
  width: 50%;
}
.zoom-image {
  width: 40%;
}
/*animation words*/

.toggle {
  position: relative;
  height: 8em;
  overflow: hidden;
}

.sentence {
  position: absolute;
  top: 0%;
  left: 0;
  right: 0;
  opacity: 0;
  animation: toggleAnimation 3s infinite;
}

.sentence:nth-child(1) {
  animation-delay: 0s;
}

.sentence:nth-child(2) {
  animation-delay: 1s;
}

.sentence:nth-child(3) {
  animation-delay: 2s;
}

@keyframes toggleAnimation {
  0%,
  20% {
    opacity: 1;
  }
  33.33%,
  100% {
    opacity: 0;
  }
}
.new-to-game {
  color: #ac9f9f;
}
.image-container {
  overflow: hidden; /* Ensure the image doesn't overflow the container */
}
.zoom-image {
  transition: transform 0.3s ease; /* Smooth transition for the zoom effect */
}

.zoom-image:hover {
  transform: scale(1.1); /* Scale up the image by 10% */
}

.rotate-image {
  transition: transform 0.3s ease; /* Smooth transition for the rotate effect */
}

.rotate-image:hover {
  animation: rotateLeftRight 1s infinite; /* Apply animation when hovered over */
}

/* Keyframes animation */
@keyframes rotateLeftRight {
  0% {
    transform: rotateY(-40deg); /* Rotate 40 degrees to the left */
  }
  50% {
    transform: rotateY(0deg); /* Rotate back to original position */
  }
  100% {
    transform: rotateY(40deg); /* Rotate 40 degrees to the right */
  }
}
/*responsive*/
/*responsive*/
/*responsive*/
/*responsive*/
/*responsive*/
/*responsive*/
/*responsive*/
/*responsive*/
/*responsive*/
/*responsive*/
/*responsive*/
/*responsive*/
/*responsive*/
/*responsive*/

/*small mobile*/
@media only screen and (max-width: 375px) {
  .section-continue {
    font-size: 16px;
    font-family: "Outfit Light";
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .text-section {
    position: absolute;
    top: 20%;
  }
  .repeat-text {
    position: absolute;
    font-size: 50px;
    top: 31%;
  }
  .rebeat {
    margin-bottom: 0;
  }
  .revolver {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -45%);
    display: flex;
    width: 120%;
    height: auto;
    overflow: hidden;
  }
  .app-container {
    position: relative;
    width: 100%;
    height: calc(100dvh - 66px) !important;
    overflow: hidden;
  }
  .section1-header {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 26px;
  }
  .outreach-container img,
  .section-container img {
    width: 80%;
    margin: 10px 0;
  }
  .sentence {
    font-size: 32px;
  }
  .toggle {
    height: 2em;
  }
  .outreach-container img {
    width: 60%;
  }
}
/*mobile and tab*/
@media only screen and (max-width: 768px) and (min-width: 375px) {
  .section-continue {
    font-size: 16px;
    font-family: "Outfit Light";
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .text-section {
    position: absolute;
    top: 20%;
  }
  .repeat-text {
    position: absolute;
    font-size: 50px;
    top: 31%;
  }
  .rebeat {
    margin-bottom: 0;
  }
  .revolver {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -44%);
    display: flex;
    width: 125%;
    height: auto;
    overflow: hidden;
  }
  .app-container {
    position: relative;
    width: 100%;
    height: calc(100dvh - 66px) !important;
    overflow: hidden;
  }
  .outreach-container img,
  .section-container img {
    width: 100%;
    margin: 10px 0;
  }
  .sentence {
    font-size: 40px;
  }
  .toggle {
    height: 3em;
  }
  .outreach-container img {
    width: 70%;
  }
}

/*tablet*/
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .responsive-text-128-second {
    font-size: 115px;
  }
  .section-continue {
    font-size: 16px;
    font-family: "Outfit Light";
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .text-section {
    position: absolute;
    top: 20%;
  }
  .repeat-text {
    position: absolute;
    font-size: 80px;
    top: 31%;
  }
  .rebeat {
    margin-bottom: 0;
  }
  .revolver {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -40%);
    display: flex;
    width: 120%;
    height: auto;
    overflow: hidden;
  }
  .app-container {
    position: relative;
    width: 100%;
    height: calc(100dvh - 66px) !important;
    overflow: hidden;
  }
  .outreach-container img,
  .section-container img {
    width: 80%;
  }
  .sentence {
    font-size: 80px;
  }
  .toggle {
    height: 5em;
  }
  .outreach-container img {
    width: 60%;
  }
}
