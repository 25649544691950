.reason-title {
  font-family: "Outfit Black";
  color: var(--color-primary);
  padding-right: 10px;
  font-size: 48px;
}

.reason-number {
  font-family: "Outfit Bold";
  font-size: 36px;
}

.reason-body {
  font-family: "Outfit Regular";
  font-size: 26px;
}
