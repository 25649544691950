.hero-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 5%;
  justify-content: center;
}

.hero-item {
  width: 45%;
  padding: 10px;
}
.hero-left-margin {
  margin-top: 2em !important;
}
.hero-title {
  display: flex;
  font-family: "Outfit Bold";
  font-size: 5.8rem;
  height: 5.8rem;
  color: white;
  overflow: hidden;
}

.word {
  display: block;
  height: 100%;
  color: var(--color-primary);
  padding-left: 15px;
  animation: spin-words 10s infinite;
}
@keyframes spin-words {
  0%,
  100% {
    transform: translateY(0%);
  }
  25%,
  75% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(-200%);
  }
}

.social-media-icons {
  margin-top: 20px !important;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 60%;
}

.hero-btn {
  display: block;
  font-family: "Outfit SemiBold";
  font-size: 2rem !important;
  color: black !important;
  background-color: var(--color-primary);
  border-radius: 36.5px;
  padding: 12px 0;
  margin-top: 40px;
  text-align: center;
  transition: all 1s linear;
}

.brain-img {
  width: 45rem;
  height: 45rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  animation: brain-animation 10s infinite linear;
  transition: all 5s linear;
}

@keyframes brain-animation {
  0%,
  100% {
    background-image: url("../../assets/images/brain-1.png");
    transform: translateY(-3%);
  }
  25%,
  75% {
    background-image: url("../../assets/images/brain-2.png");
    transform: translateY(0);
  }
  50% {
    background-image: url("../../assets/images/brain-3.png");
    transform: translateY(3%);
  }
}
.icon-container {
  width: 4rem;
  height: 4rem;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 1s linear;
}

.facebook-icon {
  background-image: url("../../assets/images/facebook-1.svg");
}
.instagram-icon {
  background-image: url("../../assets/images/instagram-1.svg");
}

.linkedin-icon {
  background-image: url("../../assets/images/linkedin-1.svg");
}
.email-icon {
  background-image: url("../../assets/images/email-1.svg");
}
@media only screen and (max-width: 1600px) {
  .hero-left-margin {
    margin-top: 2em !important;
  }
  .hero-title {
    font-size: 4.5rem;
    height: 4.5rem;
  }
  .brain-img {
    width: 38rem;
    height: 38rem;
  }
  .icon-container {
    width: 3.25rem;
    height: 3.25rem;
  }
  .hero-btn {
    font-size: 1.6rem !important;
  }
  .hero-btn:hover {
    color: #1c1950 !important;
    background-color: #88cfd0;
  }
  .instagram-icon:hover {
    background-image: url("../../assets/images/instagram-2.svg");
  }
  .linkedin-icon:hover {
    background-image: url("../../assets/images/linkedin-2.svg");
  }
  .email-icon:hover {
    background-image: url("../../assets/images/email-2.svg");
  }
  .facebook-icon:hover {
    background-image: url("../../assets/images/facebook-2.svg");
  }
}
@media only screen and (max-width: 1300px) {
  .hero-left-margin {
    margin-top: 4em !important;
  }
  .hero-title {
    font-size: 3.9rem;
    height: 3.9rem;
  }
  .brain-img {
    width: 32rem;
    height: 32rem;
  }
  .icon-container {
    width: 3.25rem;
    height: 3.25rem;
  }
  .hero-btn {
    font-size: 1.6rem !important;
  }
  .hero-btn:hover {
    color: #1c1950 !important;
    background-color: #88cfd0;
  }
  .instagram-icon:hover {
    background-image: url("../../assets/images/instagram-2.svg");
  }
  .linkedin-icon:hover {
    background-image: url("../../assets/images/linkedin-2.svg");
  }
  .email-icon:hover {
    background-image: url("../../assets/images/email-2.svg");
  }
  .facebook-icon:hover {
    background-image: url("../../assets/images/facebook-2.svg");
  }
}
@media only screen and (max-width: 1105px) {
  .hero-left-margin {
    margin-top: 4em !important;
  }
  .hero-title {
    font-size: 3.25rem;
    height: 3.25rem;
  }
  .brain-img {
    width: 30rem;
    height: 30rem;
  }
  .icon-container {
    width: 3rem;
    height: 3rem;
  }
  .hero-left-margin {
    margin-top: 1.25em;
  }
  .hero-btn {
    font-size: 1.5rem !important;
  }
  .hero-btn:hover {
    color: #1c1950 !important;
    background-color: #88cfd0;
  }
  .instagram-icon:hover {
    background-image: url("../../assets/images/instagram-2.svg");
  }
  .linkedin-icon:hover {
    background-image: url("../../assets/images/linkedin-2.svg");
  }
  .email-icon:hover {
    background-image: url("../../assets/images/email-2.svg");
  }
  .facebook-icon:hover {
    background-image: url("../../assets/images/facebook-2.svg");
  }
}
/* test */
@media only screen and (max-width: 962px) {
  .hero-title {
    font-size: 2.8rem;
    height: 2.8rem;
  }
  .brain-img {
    width: 26rem;
    height: 26rem;
  }
  .icon-container {
    width: 2.5rem;
    height: 2.5rem;
  }
  .hero-left-margin {
    margin-top: 1.25em;
  }
  .hero-btn {
    font-size: 1.3rem !important;
  }
  .hero-btn:hover {
    color: #1c1950 !important;
    background-color: #88cfd0;
  }
  .instagram-icon:hover {
    background-image: url("../../assets/images/instagram-2.svg");
  }
  .linkedin-icon:hover {
    background-image: url("../../assets/images/linkedin-2.svg");
  }
  .email-icon:hover {
    background-image: url("../../assets/images/email-2.svg");
  }
  .facebook-icon:hover {
    background-image: url("../../assets/images/facebook-2.svg");
  }
}
@media only screen and (max-width: 884px) {
  .hero-title {
    font-size: 2.75rem;
    height: 2.75rem;
  }
  .brain-img {
    width: 25rem;
    height: 25rem;
  }
  .icon-container {
    width: 2.25rem;
    height: 2.25rem;
  }
  .hero-left-margin {
    margin-top: 1em;
  }
  .hero-container {
    height: auto !important;
  }
  .hero-btn {
    font-size: 1.25rem !important;
  }
}
@media only screen and (max-width: 820px) {
  .hero-title {
    font-size: 2.5rem;
    height: 2.5rem;
  }
  .brain-img {
    width: 23rem;
    height: 23rem;
  }
  .icon-container {
    width: 2.25rem;
    height: 2.25rem;
  }
  .hero-left-margin {
    margin-top: 1em;
  }
  .hero-btn {
    font-size: 1.2rem !important;
  }
}
@media only screen and (max-width: 768px) {
  /* .hero-item {
    width: 100%;
  } */
  .hero-title {
    font-size: 2.125rem;
    height: 2.125rem;
  }
  .brain-img {
    width: 20rem;
    height: 20rem;
  }
  .icon-container {
    width: 2.25rem;
    height: 2.25rem;
  }
  .hero-left-margin {
    margin-top: 0em;
  }
  /* .hero-container {
    flex-direction: column;
    align-items: center;
    justify-content: start;
  } */
  .hero-btn {
    font-size: 1.2rem !important;
  }
  .social-media-icons {
    width: 100%;
    margin-left: 0px;
    justify-content: space-evenly;
  }
  .brain-container {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 430px) {
  .hero-left-margin {
    margin-top: 2em !important;
  }
  .hero-item {
    width: 80%;
  }
  .hero-title {
    font-size: 2rem;
    height: 2rem;
  }
  .brain-img {
    width: 18rem;
    height: 18rem;
  }
  .icon-container {
    width: 2.25rem;
    height: 2.25rem;
  }
  /* .hero-container {
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 100vh;
  } */
  .hero-btn {
    font-size: 1.1rem !important;
  }
  .social-media-icons {
    width: 100%;
    margin-left: 0px;
    justify-content: space-evenly;
  }
  .brain-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 360px) {
  .hero-left-margin {
    margin-top: 0em !important;
  }
  .hero-item {
    width: 80%;
  }
  .hero-title {
    font-size: 2rem;
    height: 2rem;
  }
  .brain-img {
    width: 18rem;
    height: 18rem;
  }
  .icon-container {
    width: 2.1rem;
    height: 2.1rem;
  }
  /* .hero-container {
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 100vh;
  } */
  .hero-btn {
    font-size: 1rem !important;
  }
  .social-media-icons {
    width: 100%;
    margin-left: 0px;
    justify-content: space-evenly;
  }
  .brain-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 320px) {
  .hero-left-margin {
    margin-top: 0em !important;
  }
  .hero-item {
    width: 100%;
  }
  .hero-title {
    font-size: 2rem;
    height: 2rem;
  }
  .brain-img {
    width: 12rem;
    height: 12rem;
  }
  .icon-container {
    width: 2.1rem;
    height: 2.1rem;
  }
  /* .hero-container {
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 100vh;
  } */
  .hero-btn {
    font-size: 1rem !important;
  }
  .social-media-icons {
    width: 100%;
    margin-left: 0px;
    justify-content: space-evenly;
  }
  .brain-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

