.counter-t1 {
  font-family: "Outfit Bold";
  /* padding: 0 10px; */
  padding-left: 10px;
}

.counter-t2 {
  font-family: "Outfit Bold";
  /* padding-right: 10px; */
}

.counter-container {
  font-size: 26px;
  font-family: "Outfit Regular";
}
