.pageNotFound{
  height: 70vh;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageNotFound h2{
  color: #ff9e01;
  font-weight: 800;
  font-size: 80px;
}
@media only screen and (max-width: 700px) {
  .pageNotFound h2{
font-size: 24px;
  }
  .pageNotFound p{
    font-size: 16px;
      }
}