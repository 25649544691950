* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mainSection {
  position: relative;
  min-height: 100vh;
  padding-top: 150px;
}

.mainSection .manImg {
  text-align: left;
  object-fit: contain;
  position: absolute;
  bottom: 10px;
  height: 85vh;
  z-index: 250;
}

.swiper {
  z-index: 300;
}

.mainSection h2 {
  color: white;
  font-family: "Outfit Bold";
  font-size: 35px;
  text-transform: uppercase;
  line-height: 50px;
  width: 562px;
  text-align: center;
  margin-left: auto;
  margin-right: 230px;
  margin-top: 20px;
  position: relative;
  z-index: 20;
}

[data-animate] {
  opacity: 0;
  transition: all 0.8s ease-out;
}

[data-animate="bottom"] {
  transform: translate3d(0, 15px, 0);
}

.animeslide-bottom {
  width: 100%;
  border-radius: 8px;
  z-index: 1;
  padding: 35px 35px;
  right: 0;
  font-size: 14px;
}


.swiper-slide>div {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.mainSection h3 {
  color: white;
  font-family: "Outfit Bold";
  font-size: 35px;
  text-transform: uppercase;
  line-height: 50px;
}

.swiper-slide .contentMain {
  color: white;
  margin-top: 10px;
  width: 562px;
  margin-right: 230px;
  margin-left: auto;
  text-align: center;
  z-index: 234;
}

.swiper-slide .content {
  box-shadow: 0px 0px 181.6px 0px #ffffff1a inset;
  /* display: flex; */
  font-size: 20px;
  margin-left: auto;
  font-weight: 500;
  color: white;
  justify-content: center;
  padding: 10px;
  align-items: center;
  margin-top: 20px;
  margin: auto;
  width: 512px;
  height: 280px;
  border-radius: 39px;
}

.swiper-slide .content img {
  object-fit: contain;
  width: 98%;
  height: 98%;
  border-radius: 39px;
}

.swiper-slide .content img.imgDiffer {
  object-fit: cover;
  object-position: top;
}

.swiper-slide .content img.imgDifferw {
  width: 480px;
  height: 260px;
  object-fit: cover;
  object-position: top;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  top: 42% !important;
  right: 2%;

  cursor: url("../../assets/images/white-mouse.svg") 0 0, pointer !important;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  top: 42% !important;
  left: 2%;

  cursor: url("../../assets/images/white-mouse.svg") 0 0, pointer !important;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  color: #f08723 !important;
  font-weight: 800 !important;
  font-size: 32px !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: #f08723 !important;
  font-weight: 800 !important;
  font-size: 32px !important;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex;
  justify-content: space-around;
  border-top: 2px solid #f08723;
  bottom: 0px;
  padding: 0 180px;
  z-index: 300;
  left: -10px;
  cursor: unset !important;
}

.swiper-pagination-bullet {
  background: none !important;
  cursor: url("../../assets/images/white-mouse.svg") 0 0, pointer !important;
}

.mainSlide {
  height: 85vh !important;
  padding: 20px;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;
}

.swiper-slide-active [data-animate] {
  opacity: 1;
  transform: none;
}

.swiper-slide-active .animeslide-desc {
  transition-delay: 0.5s;
}

.swiper-pagination-bullet {
  font-family: "Outfit ExtraLight";
  font-size: 22px;
  line-height: 27.06px;
  text-align: center;
  color: #afaeae !important;
  opacity: 1 !important;
  margin: 0 !important;
  padding: 10px 0;
  width: unset !important;
  height: unset !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  font-weight: 200;
  line-height: 27.06px;
  text-align: center;
  color: #ffffff !important;
  /* box-shadow: 0px 4px 24.2px 0px #FFFFFF99; */
  opacity: 1 !important;
}

.calenderLink {
  position: absolute;
  right: 60px;
  color: white;
  right: 40px;
  bottom: 20%;
  z-index: 400;
}

.calenderLink img {
  width: 30px !important;
}


@media screen and (max-height: 800px) {
  .mainSlide>div {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

  }


  .mainSection {
    padding-top: 90px;

  }
}

@media screen and (max-height: 650px) {
  .swiper-slide .content {
    height: 250px;
    width: 400px !important;

  }

  .swiper-slide .content .imgDifferw {
    height: 220px !important;
    width: 380px !important;
  }
}

@media screen and (max-width: 1025px) {

  .swiper-slide .contentMain {
    width: 462px;
    margin-right: 40px;
  }

  .swiper-slide .content {
    width: 412px;
  }

  .mainSection h2 {
    margin-right: 10px;
    margin-top: 10px;
    font-size: 28px;
  }

  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    padding: 0 10px;
  }

  .mainSection h3 {
    font-size: 28px;
  }
}

@media screen and (max-width: 900px) {
  .swiper {
    z-index: 300;
  }
  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after ,.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
font-size: 18px !important;
color: #f08723 !important;
  }

  .mainSection {
    position: relative;
    height: 95vh;
  }

  .swiper-pagination-bullet {
    font-size: 10px;
  }

  [data-animate] {
    transition: unset !important;
  }

  .mainSection .manImg {
    text-align: left;
    z-index: 222;
    object-fit: contain;
    position: absolute;
    height: 58vh !important;
    left: 50px;
    bottom: 115px;
  }

  .mainSection h3 {
    display: none;
    color: white;
    font-family: "Outfit Bold";
    font-size: 20px;
    margin-left: -80px;
  }

  .calenderLink {
    right: 5% !important;
    bottom: 40% !important;
  }

  /* .swiper {
    overflow-x: hidden;
  } */

  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 100px;
    padding: 0 10px;
  }




  [data-animate="bottom"] {
    transform: translate3d(15px, 0px, 0);
  }

  .mainSection h3 {
    font-size: 22px;
  }

  .calenderLink {
    position: absolute;
    right: -40px;
    color: white;
    bottom: 30%;
  }

}


@media screen and (max-width: 767px) {
  .swiper{
    overflow: unset !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 5% !important;
    right: 4%;
  
  }
  
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 5% !important;
    left: 4%;
  
  }
  .mainSection{
    overflow: hidden;
  }
  .mainSection h2 {
    color: white;
    font-family: "Outfit Bold";
    font-size: 20px;
    margin-left: 0; 
    margin-bottom: 0; 
    text-align: left;
    position: absolute;
    top: -17%;
    width: 100%;
    left: 0%;
    text-align: center;
    z-index: 22;
}
  .swiper-slide .content {
    border-radius: 39px !important;
    position: absolute;
    top: -8%;
    background-color: #191919;
    width: 80% !important;
    left: 10%;
    height: 180px !important;
    font-size: 14px;
    overflow: hidden;
    z-index: 2;
    transition: unset !important;
  }



  .swiper-slide .content {
    transition: unset !important;
  }

  .swiper-slide .content img {

    width: 95% !important;
    opacity: 1;
    height: 95% !important;
    object-fit: contain;
    object-position: center;
  }


  .swiper-slide .content img.imgDiffer {
    position: unset;
    object-fit: contain !important;
    width: 100% !important;
    opacity: 1;
    height: 250px !important;
    object-position: center 30px !important;
    margin-top: -25px;
  }

  .content .swiper-slide .imgDifferw {
    position: unset;
    object-fit: contain !important;
    width: 100% !important;
    opacity: 1;
    height: 250px !important;
    object-position: center 30px !important;
    margin-top: -25px;
  }

}
/* @media screen and (max-width: 767px) and (max-height: 928px) {
  .swiper-slide .content img.imgDiffer {
    height: 200px !important;

    margin-top: -35px;
  }
} */
@media screen and (max-width: 767px) and (max-height: 896px) {
  .mainSection .manImg {
   
    height: 52vh !important;
 
  }

}
@media screen and (max-width: 927px) and (max-height: 500px) {
  .swiper-slide .content {
    border-radius: 39px !important;
    position: absolute;
    top: -28%;
    background-color: #191919;
    width: 300px !important;
    right: 10%;
    height: 180px !important;
    font-size: 14px;
    overflow: hidden;
    z-index: 2;
    transition: unset !important;
  }
  .mainSection .manImg {
   
    height: 78vh !important;
 
  }
  .mainSection h2 {

display: none;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 0% !important;
    right: 4%;
  
  }
  
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    top: 0% !important;
    left: 4%;
  
  }
}

/* .anny{
height: 100vh;
} */