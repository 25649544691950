.loading-screen {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-circle {
  width: 250px;
  height: 250px;
  background-color: var(--color-primary);
  backdrop-filter: blur(73.8px);
  border-radius: 9999px;
  position: absolute;

  animation: scaling 2s linear infinite;
}
.loading-img {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-imgg {
  height: 100px;
  width: auto;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotatimg 2s linear infinite;
}

@keyframes scaling {
  0%,
  100% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(1.3);
  }
}
@keyframes rotatimg {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
