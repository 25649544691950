.BatManLeft {
  margin-top: 20px;
}

.BatManLeft img {
  width: 60%;
  height: auto;
}

.BatManRight {
  margin-top: 60px;
}

.BatManRight img {
  width: 55%;
  height: auto;
}
.BatManRight img {
  margin-left: auto;
  display: block;
  margin-top: -20%;
}
