.nav-bar-container {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 0;
  z-index: 9998;
}
.nav-bar {
  position: fixed;
  width: 80%;
  top: 0;
  background: linear-gradient(266.02deg, #232323 61.52%, #606060 139.35%);
  box-shadow: inset 0px 0px 85px rgba(80, 80, 80, 0.5);
  height: 98px;
  border-radius: 0 0 50px 50px;
  transition: all 1s ease;
  overflow: hidden;
}

.nav-bar:hover {
  height: 240px;
}
.nav-bar-logo {
  position: fixed;
  top: 0;
  left: 14%;
  height: 100px;
  width: 100px;
}
.logo-part {
  position: absolute;
  max-width: 100%;
  height: auto;
}
.nav-bar-links-container {
  display: flex;
  justify-content: center;
  height: 24px;
  margin: 35px 5% 0 15%;
}
.nav-bar-line {
  position: fixed;
  height: 5px;
  width: 60%;
  left: 25.5%;
  top: 75px;
  background: rgba(132, 132, 132, 0.6);
  opacity: 0.5;
  box-shadow: inset 0px 0px 20.6px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(73.8px);
  border-radius: 13px;
}

.dc-txt {
  width: 70px;
  height: auto;
  transform: translate(0%, 200%);
  transition: all ease;
  visibility: hidden;
}

.dc-body,
.dc-border,
.dc-key {
  transition: all 0.5s ease;
}

.nav-bar:hover .dc-body {
  transform: translateX(-20%);
}

.nav-bar:hover .dc-border {
  transform: translateX(-10%);
}
.nav-bar:hover .dc-key {
  transform: rotate(90deg) translateX(10%) translateY(5%);
}
.nav-bar:hover .dc-txt {
  transition-delay: 0.1s;
  visibility: visible;
  transform: translate(0%, 280%);
}

.nav-bar:hover .nav-bar-link-1 {
  color: var(--color-primary);
}
.nav-bar:hover .nav-bar-link-2 {
  transition-delay: 0.5s;
  color: var(--color-primary);
}
.nav-bar:hover .nav-bar-link-3 {
  transition-delay: 1s;
  color: var(--color-primary);
}
.nav-bar:hover .nav-bar-link-4 {
  transition-delay: 1.5s;
  color: var(--color-primary);
}
.nav-bar:hover .nav-bar-link-font {
  font-weight: 700;
}
.nav-bar:hover .nav-bar-expanded {
  height: 150px;
}

.offcanvas {
  color: white;
  font-family: "Outfit SemiBold";
}
.offcanvas-body {
  z-index: 999999 !important;
  background-color: rgba(25, 25, 25, 1);
  overflow-y: visible;
}
.logo {
  width: 50px;
  height: 50px;
}
.btn-close {
  --bs-btn-close-bg: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%201024%201024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23000000%22%3E%3Cg%20id%3D%22SVGRepo_bgCarrier%22%20stroke-width%3D%220%22%3E%3C%2Fg%3E%3Cg%20id%3D%22SVGRepo_tracerCarrier%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3C%2Fg%3E%3Cg%20id%3D%22SVGRepo_iconCarrier%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M195.2%20195.2a64%2064%200%200%201%2090.496%200L512%20421.504%20738.304%20195.2a64%2064%200%200%201%2090.496%2090.496L602.496%20512%20828.8%20738.304a64%2064%200%200%201-90.496%2090.496L512%20602.496%20285.696%20828.8a64%2064%200%200%201-90.496-90.496L421.504%20512%20195.2%20285.696a64%2064%200%200%201%200-90.496z%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  --bs-btn-close-opacity: 1;
}
.badge {
  --bs-badge-color: var(--color-primary);
}
