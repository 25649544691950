.footer {
  width: 100%;
  color: #fff;
  padding: 20px;
  bottom: 0;
  border-bottom: var(--color-primary) 4px solid;
}
.company-name {
  font-family: "Outfit SemiBold";
}
.footer-title {
  font-family: "Outfit Medium";
  font-size: 60px;
  line-height: 100%;
  color: rgba(255, 255, 255, 0.85);
}
.footer-link {
  font-family: "Outfit Medium";
  font-size: 24px;
  line-height: 100%;
  color: #ffffff;
}
.footer-link-items {
  display: block;
  font-family: "Outfit ExtraLight";
  font-size: 20px;
  line-height: 100%;
  color: #dcdcdc;
}
.footer-info {
  font-family: "Outfit Light";
  font-size: 20px;
  line-height: 100%;
  color: #fff;
}
.footer-privacy {
  font-family: "Outfit Regular";
  font-size: 16px;
  line-height: 100%;
  color: #fff;
}
.footer-link-items:hover {
  color: var(--color-primary);
}
.footer-icon-container {
  width: 35.45px;
  height: 35.45px;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 1s linear;
}
.facebook-footer-icon {
  background-image: url("../../assets/images/facebook-footer.svg");
}

.instagram-footer-icon {
  background-image: url("../../assets/images/instagram-footer.svg");
}

.linkedin-footer-icon {
  background-image: url("../../assets/images/linkedin-footer.svg");
}

.email-footer-icon {
  background-image: url("../../assets/images/email-footer.svg");
}
.twitter-footer-icon {
  background-image: url("../../assets/images/twitter-footer.svg");
}
.whatsapp-footer-icon {
  background-image: url("../../assets/images/whatsapp-footer.svg");
}
.footer-social-container {
  border-bottom: var(--color-primary) 1px solid;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 884px) {
  .footer-title {
    font-size: 52px !important;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .footer-title {
    font-size: 48px !important;
    margin-bottom: 20px;
  }
  .footer-info {
    font-size: 16px;
  }
  .footer-privacy {
    font-size: 16px;
  }
}
@media only screen and (max-width: 430px) {
  .footer-title {
    font-size: 30px !important;
    margin-bottom: 20px;
  }
  .footer-privacy {
    font-size: 14px;
  }
  .footer-icon-container {
    width: 28px;
    height: 28px;
  }
}
@media only screen and (max-width: 360px) {
  .footer-title {
    font-size: 30px !important;
    margin-bottom: 20px;
  }
  .footer-privacy {
    font-size: 12px;
  }
  .footer-link {
    font-size: 20px;
  }
  .footer-link-items {
    font-size: 16px;
  }
}
