.outsourcingtext {
  background-image: url("../../assets/images/out-sourcing-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: auto;
  margin-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  background-position: center;
}
.Arrows {
  background-image: url("../../assets/images/Layer_1.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  animation: slideBackground 6s linear infinite;
}

@keyframes slideBackground {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}
.arrow1 {
  margin-top: 5%;
  margin-bottom: 2%;
  margin-right: 20%;
  width: 50%;
  height: auto;
}

.animated-up-down {
  animation: moveDownUp 0.7s infinite alternate;
}
.animated-up-down2 {
  animation: moveUpDown 1s infinite alternate;
}
@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}
@keyframes moveDownUp {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.textright {
  width: 35%;
  margin-left: 60%;
  margin-top: 4%;
}

.textleft {
  width: 35%;
  margin-top: 4%;
}
.textleft2 {
  width: 50%;
  margin-top: 4%;
}
.logodc {
  width: 142px;
  height: 147px;
  margin-top: 2%;
}
.responsive-text-24 {
  font-family: "Outfit Light";
  font-size: 24px;
  text-align: left;
}

@media only screen and (max-width: 1440px) {
  .arrow1 {
    width: 30%;
  }
  .arrow2 {
    width: 70%;
  }
  .responsive-text-24 {
    font-size: 24px;
  }
  .Arrows {
    height: 100vh;
  }
}

@media only screen and (max-width: 1280px) {
  .arrow1 {
    width: 30%;
  }
  .arrow2 {
    width: 70%;
  }
  .responsive-text-24 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1024px) {
  .arrow1 {
    width: 50%;
  }
  .arrow2 {
    width: 90%;
  }
  .responsive-text-24 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 884px) {
  .outsourcingtext {
    background-image: none;
  }
  .arrow1 {
    width: 30%;
  }
  .arrow2 {
    width: 70%;
  }
  .textright {
    width: 100%;
    margin-left: 0%;
  }
  .textleft {
    width: 100%;
  }
  .textleft2 {
    width: 100%;
  }
  .responsive-text-24 {
    font-size: 18px;
  }
  .responsive-text-24 h2 {
    font-family: "Outfit Light";
    font-size: 24px;
    text-align: center;

    font-weight: bold;
  }
  .Arrows {
    height: auto;
  }
  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-20px);
    }
  }
  @keyframes moveDownUp {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0);
    }
  }
}
@media only screen and (max-width: 768px) {
  .arrow1 {
    width: 40%;
  }
  .arrow2 {
    width: 80%;
  }
  .textright {
    width: 100%;
    margin-left: 0%;
  }
  .textleft {
    width: 100%;
  }
  .textleft2 {
    width: 100%;
  }
  .responsive-text-24 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 430px) {
  .arrow1 {
    width: 30%;
  }
  .arrow2 {
    width: 80%;
  }
  .textright {
    width: 100%;
    margin-left: 0%;
  }
  .textleft {
    width: 100%;
  }
  .textleft2 {
    width: 100%;
  }
  .responsive-text-24 {
    font-size: 18px;
  }
  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-15px);
    }
  }
  @keyframes moveDownUp {
    0% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0);
    }
  }
}
