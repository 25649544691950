.nav-bar-link-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  flex-basis: 25%;
}

.nav-bar-link-font {
  font-family: "Outfit Regular";
  color: white;
  font-size: 20px;
  transition: color 1s ease;
}

.nav-bar-link-font:hover {
  transition-delay: 0s !important;
  color: white !important;
}

.nav-bar-link-items-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  height: 100px;
}

.nav-bar-link-items-font {
  display: block;
  font-family: "Outfit Regular";
  color: white;
  font-size: 16px;
  margin-top: 1px;
}

.nav-bar-link-items:hover {
  color: var(--color-primary) !important;
}

.new-style {
  color: var(--color-primary);
}
