.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(25, 25, 25, 1);
  z-index: 9999 !important;
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animateRadius {
  0%,
  100% {
    r: 105;
  }
  50% {
    r: 55;
  }
}

@keyframes animateRadius2 {
  0%,
  100% {
    r: 133.5;
  }
  50% {
    r: 70;
  }
}

.rotate-svg {
  width: 50vmin;
  height: 50vmin;
  animation: rotateAnimation 2s linear infinite;
}

.circle1 {
  animation: animateRadius 1s infinite;
}

.circle2 {
  animation: animateRadius2 1s infinite;
}
