:root {
  --color-primary: #ff9f00;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Outfit Black";
  src: url("./assets/fonts/Outfit-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Outfit ExtraBold";
  src: url("./assets/fonts/Outfit-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Outfit Bold";
  src: url("./assets/fonts/Outfit-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Outfit SemiBold";
  src: url("./assets/fonts/Outfit-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Outfit Medium";
  src: url("./assets/fonts/Outfit-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Outfit Regular";
  src: url("./assets/fonts/Outfit-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Outfit Light";
  src: url("./assets/fonts/Outfit-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Outfit ExtraLight";
  src: url("./assets/fonts/Outfit-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Outfit Thin";
  src: url("./assets/fonts/Outfit-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Zcool Regular";
  src: url("./assets/fonts/Zcool-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  background-color: rgba(25, 25, 25, 1);
  cursor: url("./assets/images/black-mouse.svg"), auto !important;
}
body button,
a,
select,
input[type="submit"],
:link,
:visited,
a > *,
img,
body .custom-cursor-on-hover {
  cursor: url("./assets/images/white-mouse.svg") 0 0, pointer !important;
}
a {
  text-decoration: none;
  color: white;
}
a:hover {
  color: var(--color-primary);
}
.width-100 {
  width: 100% !important;
}
.height-100 {
  height: 100% !important;
}
.responsive-text-60 {
  font-family: "Outfit Bold";
  font-size: 60px;
}

.responsive-text-48 {
  font-family: "Outfit Medium";
  font-size: 48px;
  color: var(--color-primary);
}

.responsive-text-45 {
  font-family: "Outfit ExtraLight";
  font-size: 45px;
}
.responsive-text-45:hover {
  color: var(--color-primary);
  font-family: "Outfit Medium";
}
.responsive-text-36 {
  font-family: "Outfit Light";
  font-size: 36px;
  text-align: justify;
}
.responsive-text-128 {
  font-size: 128px;
}

.first-section-padding {
  padding-top: 110px;
}
.full-height {
  height: calc(100dvh) !important;
}
.font-light {
  font-family: "Outfit Light" !important;
}

.custom-btn {
  font-family: "Outfit Bold";
  font-size: 48px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  transition: background-color 0.3s;
  border-radius: 100px;
  width: 50%;
}
.custom-btn:hover {
  background-color: #c67e07;
}

@media (max-width: 1105px) {
  .first-section-padding {
    padding-top: 5em !important;
  }
}
@media (max-width: 884px) {
  .full-height {
    height: calc(100dvh - 66px) !important;
  }
  .responsive-text-60 {
    font-size: 45px;
  }

  .responsive-text-48 {
    font-size: 32px;
  }

  .responsive-text-45 {
    font-size: 34px;
  }
  .responsive-text-36 {
    font-size: 32px;
  }
  .first-section-padding {
    padding-top: 0em !important;
  }
}
@media (max-width: 768px) {
  .responsive-text-128 {
    font-size: 96px;
  }
  .responsive-text-60 {
    font-size: 45px;
  }

  .responsive-text-48 {
    font-size: 32px;
  }

  .responsive-text-45 {
    font-size: 34px;
  }
  .responsive-text-36 {
    font-size: 27px;
  }
  .first-section-padding {
    padding-top: 0em !important;
    /* margin-top: 30px !important; */
  }
  .custom-btn {
    font-size: 2.25rem;
    border-radius: 100px;
    width: 50%;
  }
}
@media only screen and (max-width: 425px) {
  .custom-btn {
    font-size: 1.3rem;
    border-radius: 100px;
    width: 50%;
  }
}

@media (max-width: 480px) {
  .responsive-text-128 {
    font-size: 52px;
  }
  .responsive-text-60 {
    font-size: 30px;
  }

  .responsive-text-48 {
    font-size: 18px;
  }

  .responsive-text-45 {
    font-size: 18px;
  }

  .responsive-text-36 {
    font-size: 22px;
  }
}

.header-style {
  color: var(--color-primary) !important;
  font-family: "Outfit Black";
}

.link-style {
  color: var(--color-primary) !important;
}
.link-style:hover {
  color: white !important;
}

.header-under-line {
  text-decoration: underline var(--color-primary);
}
.highlight {
  font-family: "Outfit Bold";
  color: var(--color-primary);
}
