/* .reasons-title::before {
  content: "";
  width: 150px;
  height: 1px;
  background-color: #ffffff;
  position: absolute;
  margin-top: 80px;
} */
.reason-title,
.x-animation {
  font-family: "Outfit Black";
}
.reason-body {
  font-family: "Outfit Regular";
}
.reason-one-animation {
  font-family: "Outfit Bold";
  font-size: 32px;
  padding-left: 10px;
  letter-spacing: 2px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  width: 550px;
}

.run-animation-one {
  animation: typing 5s steps(29), cursor 0.4s step-end infinite alternate;
  border-right: 2px solid;
}
@keyframes cursor {
  50% {
    border-color: transparent;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
}

.reason-two-animation {
  font-family: "Outfit Bold";
  font-size: 26px;
  transition: font-weight 4s linear;
}
.run-animation-two {
  font-weight: 800;
}
.reasons-title {
  font-size: 60px !important;
  margin-bottom: 20px;
  font-family: "Outfit SemiBold";
  border-bottom: #ffffff 1px solid;
}

.price-animation {
  font-family: "Outfit Bold";
  color: var(--color-primary);
}
.price-alpha {
  animation: flipping 2s linear infinite;
}
.price-alpha-2 {
  animation-delay: 0.3s;
}
.price-alpha-3 {
  animation-delay: 0.5s;
}
.price-alpha-4 {
  animation-delay: 0.7s;
}
.price-alpha-5 {
  animation-delay: 0.9s;
}
.x-animation {
  transition: all 4s ease-in-out;
}
.x-animation-run {
  transform: scale(1);
  font-size: 2em;
  color: var(--color-primary);
}

@keyframes flipping {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}
@media only screen and (max-width: 1440px) {
  .reasons-title {
    font-size: 60px !important;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 1280px) {
}
@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 884px) {
  .reasons-title {
    font-size: 52px !important;
    margin-bottom: 20px;
  }
  .reason-title {
    font-size: 41.6px !important;
  }
  .reason-number {
    font-size: 31.2px !important;
  }
  .reason-body {
    font-size: 22.5px !important;
  }
  .counter-t1 {
    padding: 0 8.6px !important;
  }
  .counter-t2 {
    padding-right: 8.6px !important;
  }
  .counter-container {
    font-size: 22.5px !important;
  }
  .reason-one-animation {
    font-size: 27.7px;
    letter-spacing: 1px;
    width: 450px;
  }
  .reason-two-animation {
    font-size: 22.5px;
  }
  .run-animation-two {
    font-weight: 700;
  }
  .reasons-title::before {
    margin-top: 68.8px;
  }
}
@media only screen and (max-width: 768px) {
  .reasons-title {
    font-size: 48px !important;
    margin-bottom: 20px;
  }
  .reason-title {
    font-size: 38.4px !important;
  }
  .reason-number {
    font-size: 28.8px !important;
  }
  .reason-body {
    font-size: 20.8px !important;
  }
  .counter-t1 {
    padding: 0 8px !important;
  }
  .counter-t2 {
    padding-right: 8px !important;
  }
  .counter-container {
    font-size: 20.8px !important;
  }
  .reason-one-animation {
    font-size: 25.6px;
    letter-spacing: 1px;
    width: 420px;
  }
  .reason-two-animation {
    font-size: 20.8px;
  }
  .run-animation-two {
    font-weight: 700;
  }
  .reasons-title::before {
    margin-top: 64px;
  }
}
@media only screen and (max-width: 430px) {
  .reasons-title {
    font-size: 28px !important;
    margin-bottom: 20px;
  }
  .reason-title {
    font-size: 24px !important;
  }
  .reason-number {
    font-size: 19.2px !important;
  }
  .reason-body {
    font-size: 13.8px !important;
  }
  .counter-t1 {
    padding: 0 5.3px !important;
  }
  .counter-t2 {
    padding-right: 5.3px !important;
  }
  .counter-container {
    font-size: 13.8px !important;
  }
  .reason-one-animation {
    font-size: 18px;
    letter-spacing: 1px;
    width: 310px;
  }
  .reason-two-animation {
    font-size: 13.8px;
  }
  .run-animation-two {
    font-weight: 700;
  }
  .reasons-title::before {
    margin-top: 42.6px;
  }
  .WhyDoorCloosers-body {
    padding-left: 10px;
  }
}
