.seperate-line {
  position: relative;
}

/* .seperate-line::before {
  content: "";
  width: 50%;
  height: 4px;
  position: absolute;
  bottom: -50px;
  left: 0;
  background: rgba(132, 132, 132, 0.6);
  opacity: 0.5;
  box-shadow: inset 0px 0px 20.6px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(73.8px);
  border-radius: 13px;
}

.seperate-line::after {
  content: "";
  width: 50%;
  height: 4px;
  position: absolute;
  top: -25px;
  right: 0;
  background: rgba(132, 132, 132, 0.6);
  opacity: 0.5;
  box-shadow: inset 0px 0px 20.6px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(73.8px);
  border-radius: 13px;
} */
.card-img {
  width: 250px;
  height: 250px;
}
.card-title {
  font-family: "Outfit Regular";
  font-size: 40px;
  color: var(--color-primary);
}
.appointment-img {
  background-image: url("../../assets/images/appointment-1.png");
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 10s ease-out;
}
.appointment-img:hover {
  animation: appointment-animation 5s infinite;
}

@keyframes appointment-animation {
  0%,
  100% {
    transform: translateY(-5%);
  }
  50% {
    transform: translateY(5%);
  }
}
.leads-img {
  background-image: url("../../assets/images/leads-1.png");
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 5s linear;
}
.leads-img:hover {
  animation: leads-animation 5s infinite;
}
@keyframes leads-animation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.development-img {
  background-image: url("../../assets/images/development.png");
  background-repeat: no-repeat;
  background-size: contain;
  animation: development-animation 3s infinite;
  transition: all 1s ease-in;
}

.development-img:hover {
  transform: rotate(5deg) scale(1.2);
}

.outsourcing-img {
  background-image: url("../../assets/images/outsourcing-1.png");
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 10s ease-in-out;
}
.outsourcing-img:hover {
  animation: outsourcing-animation 2s infinite;
}
@keyframes outsourcing-animation {
  0%,
  100% {
    transform: translateX(-3%);
  }
  50% {
    transform: translateX(3%);
  }
}
.why-title {
  font-family: "Outfit SemiBold";
  font-size: 60px !important;
  margin-bottom: 20px;
  color: white;
  border-bottom: #ffffff 1px solid;
}

@media only screen and (max-width: 884px) {
  .why-title {
    font-size: 52px !important;
    margin-bottom: 20px;
  }
  .card-img {
    width: 250px;
    height: 250px;
  }
  .card-title {
    font-size: 36px;
  }
}
@media only screen and (max-width: 768px) {
  .why-title {
    font-size: 42px !important;
    margin-bottom: 20px;
  }
  .card-img {
    width: 250px;
    height: 250px;
  }
  .card-title {
    font-size: 36px;
  }
}
@media only screen and (max-width: 430px) {
  .why-title {
    font-size: 30px !important;
    margin-bottom: 20px;
  }
  .card-img {
    width: 200px;
    height: 200px;
  }
  .card-title {
    font-size: 24px;
  }
}
