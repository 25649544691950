.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0px;
  width: 100%;
}
.loader {
  width: 72px;
  padding: 5px;
  margin: auto;
  aspect-ratio: 1;
  border-radius: 50%;
  background: white;
  border: 8px solid white;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.custom-input-container {
  width: 80%;
  display: flex;
  justify-content: center;
}
.custom-input {
  font-family: "Outfit Regular";
  font-size: 38px;
  background-color: transparent;
  color: white;
  width: 80%;
  height: 60px;
  outline: none;
  border-radius: 42px;
  border-width: 1px;
  border-color: white;
  border-style: solid;
  margin-bottom: 20px;
  padding-left: 40px;
  transition: color 0.3s, background-color 0.3s;
}
.textarea {
  height: 120px;
}
.custom-input:focus {
  background-color: white;
  color: black;
}

@media only screen and (max-width: 768px) {
  .custom-input-container {
    width: 100%;
  }
  .custom-input {
    font-size: 1.75rem;
    width: 80%;
    height: 3.25rem;
    border-radius: 32px;
    margin-bottom: 20px;
    padding-left: 40px;
  }
  .textarea {
    height: 6.5rem;
  }
}
@media only screen and (max-width: 425px) {
  .custom-input-container {
    width: 100%;
  }
  .custom-input {
    font-size: 1.1rem;
    width: 80%;
    height: 2.5rem;
    border-radius: 24px;
    margin-bottom: 20px;
    padding-left: 20px;
  }
  .textarea {
    height: 5rem;
  }
}
